/* You can add global styles to this file, and also import other style files */
@import "assets/layout/styles/layout/layout.scss";
@import "primeng/resources/themes/aura-light-teal/theme.css";
@import "primeng/resources/primeng.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "app/utils/scss/mixins";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import "@ctrl/ngx-emoji-mart/picker";

:root {
  font-family: "Open Sans", sans-serif !important;
  --font-family: "Open Sans", sans-serif !important;
  --star-color: #ffd700;
}

.customer-badge,
.text-badge,
.order-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.text-badge {
  &.status-active {
    color: var(--primary-color);
  }
  &.status-inactive {
    background: #ffcdd2;
    color: #c63737;
  }
}

.customer-badge {
  &.status-qualified {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-unqualified {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-negotiation {
    background: #feedaf;
    color: #8a5340;
  }
  &.status-new {
    background: #b3e5fc;
    color: #23547b;
  }
  &.status-renewal {
    background: #eccfff;
    color: #694382;
  }
  &.status-proposal {
    background: #ffd8b2;
    color: #805b36;
  }
}

.order-badge {
  &.order-delivered {
    background: #c8e6c9;
    color: #256029;
  }
  &.order-cancelled {
    background: #ffcdd2;
    color: #c63737;
  }
  &.order-pending {
    background: #feedaf;
    color: #8a5340;
  }
  &.order-returned {
    background: #eccfff;
    color: #694382;
  }
}
